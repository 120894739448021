<template>
    <div class="width-full-div">
        <div class="d-flex flex-nowrap align-center justify-center">
            <v-autocomplete
                :key="autocompleteKey"
                v-model="item"
                :label="label"
                :items="computedItems"
                :search-input.sync="locationSearchText"
                item-text="description"
                item-value="place_id"
                hide-no-data
                :dense="isDense"
                :readonly="isReadonly"
                return-object
                :rules="rules"
                @input="onSelect" />
            <v-btn
                v-if="!isExpanded && !isReadonly"
                text
                small
                fab
                class="ml-2"
                @click="isExpanded = true">
                <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
            <v-btn
                v-if="isExpanded && !isReadonly"
                text
                small
                fab
                class="ml-2"
                @click="isExpanded = false">
                <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
            <slot />
        </div>
        <div>
            <v-expand-transition>
                <v-container v-if="isExpanded" class="inputs-container">
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="valueLocal.street"
                                label="Street" 
                                :rules="rules" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="valueLocal.city"
                                label="City"
                                :rules="rules" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="valueLocal.state"
                                label="State"
                                :rules="rules" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <app-autocomplete
                                v-model="valueLocal.countryId"
                                label="Country"
                                :schema="getSchema('Countries')"
                                list-url="/api/Countries/List"
                                url="/api/Countries"
                                item-text="name"
                                :rules="rules"
                                @input="onCountrySelected" />
                        </v-col>
                        <v-col cols="4">
                            <v-text-field
                                v-model="valueLocal.postcode"
                                label="Postcode"
                                :rules="rules" />
                        </v-col>
                    </v-row>
                </v-container>
            </v-expand-transition>
        </div>
    </div>
</template>

<script>
import addressService from "@/services/addressService";

export default {
    components: {
        appAutocomplete: () => import('../components/AppAutocomplete')
    },
    props: {
        value: {
            type: Object,
            default: null,
        },
        label: {
            type: String,
            default: null,
        },
        rules: {
            type: Array,
            default: () => [],
        },
        isDense: {
            type: Boolean,
            default: false,
        },
        isReadonly: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            item: null,
            locationSearchText: null,
            items: [],
            valueLocal: {},
            isExpanded: false,
            isTransition: false,
            autocompleteKey: 0,
            searchKey: 0,
        }
    },
    computed: {
        computedItems() {
            return this.items;
        },
    },
    watch: {
        valueLocal: {
            deep: true,
            handler() {
                this.$emit('input', this.valueLocal);
            }
        },
        async locationSearchText(search) {
            if (!search) { return; }

            if (this.searchKey !== this.autocompleteKey) {
                this.searchKey = this.autocompleteKey;
                return;
            }
            this.items = await addressService.getSuggestions(search);
        },
        value: {
            deep: true,
            immediate: true,
            handler() {
                if (this.value === null || typeof (this.value) === "undefined") {
                    return;
                }
                this.valueLocal = this.value;

                let addressSections = [
                    this.value.street,
                    this.value.city,
                    this.value.state,
                    this.value.country
                ];

                let description = addressSections.filter((sec) => {
                    return sec != null
                }).join(", ");

                if (description === "") {
                    this.item = null;
                    this.items = [];
                    this.autocompleteKey++;
                }
                else if (this.item !== null && typeof (this.item) !== "undefined") {
                    let index = this.items.map(i => i.place_id).indexOf(this.item.place_id);
                    this.$set(this.item, "description", description);
                    this.$set(this.items[index], "description", description);
                    this.autocompleteKey++;
                }
                else {
                    let item = { description, place_id: description };
                    this.items.push(item);
                    this.item = item;
                    this.autocompleteKey++;
                }
            }
        },
    },
    methods: {
        async onSelect(item) {
            let address = await addressService.parseAddress(item);
            this.$emit('input', address);
        },
        
        isEmpty(value) {
            return typeof (value) === "undefined" || value === null || value === "";
        },
        onCountrySelected(item) {
            if (typeof item !== "undefined" && item !== null) {
                this.valueLocal.country = item.label;
            }
            else {
                this.valueLocal.country = "";
            }
        }
    }
};
</script>
<style>
    .inputs-container {
        margin: -15px 0px -15px 0px;
    }

        .inputs-container .col {
            padding-top: 0;
            padding-bottom: 0;
        }

        .inputs-container .theme--light.v-input {
            padding-top: 0;
        }

        .inputs-container .v-input__slot {
            margin-bottom: 0;
        }
</style>
