import axios from "axios";
import { equal } from "@/services/filtering"

export default {
    getSuggestions,
    getPlaceDetails,
    addressMappers,
    parseAddress
};
const google = window.google;

const addressMappers = [
    {
        type: "number",
        items: [
            "room",
            "floor",
            "street_number",
        ]
    },
    {
        type: "street",
        items: [
            "street_address",
            "route",
            "plus_code",
            "intersection",
        ]
    },
    {
        type: "suburb",
        items: [
            "sublocality",
            "sublocality_level_1",
            "sublocality_level_2",
            "sublocality_level_3",
            "sublocality_level_4",
            "neighborhood",
            "town_square",
            "archipelago",
            "colloquial_area",
            "establishment",
            "finance",
            "food",
            "general_contractor",
            "health",
            "landmark",
            "natural_feature",
            "place_of_worship",
            "point_of_interest",
            "post_box",
        ]
    },
    {
        type: "city",
        items: [
            "locality",
        ]
    },
    {
        type: "state",
        items: [
            "administrative_area_level_1",
            "administrative_area_level_2",
            "administrative_area_level_3",
            "administrative_area_level_4",
            "administrative_area_level_5",
            "postal_town",
        ]
    },
    {
        type: "country",
        items: [
            "country",
            "continent"
        ]
    },
    {
        type: "postcode",
        items: [
            "postal_code_prefix",
            "postal_code",
            "postal_code_suffix"
        ]
    }
]

async function getSuggestions(search) {
    let promise = await new Promise((resolve, reject) => {
        let displaySuggestions = (predictions, status) => {
            if (status !== google.maps.places.PlacesServiceStatus.OK) {
                reject(status);
            }
            resolve(predictions);
        }
        let request = {
            input: search,
            location: new google.maps.LatLng(-23.700552, 133.882675),
            radius: 2000000,
            types: ['geocode']
        }

        let service = new google.maps.places.AutocompleteService();
        service.getPlacePredictions(request, displaySuggestions);
    });

    return promise;
}

async function getPlaceDetails(placeId) {
    let promise = await new Promise((resolve, reject) => {
        let callback = (place, status) => {
            if (status !== google.maps.places.PlacesServiceStatus.OK) {
                reject(status);
            }
            resolve(place);
        }
        let request = {
            placeId: placeId
        };

        let service = new google.maps.places.PlacesService(document.createElement('div'));
        service.getDetails(request, callback);
    })

    return promise;
}

async function parseAddress(item) {
    let result = await getPlaceDetails(item.place_id);
    let latitude = result.geometry.location.lat();
    let longitude = result.geometry.location.lng();

    //Here we aim to parse the google maps address api into our database address state
    let parsedAddress = [
        { type: "number", value: "" },
        { type: "street", value: "" },
        { type: "suburb", value: "" },
        { type: "city", value: "" },
        { type: "state", value: "" },
        { type: "country", value: "" },
        { type: "postcode", value: "" }
    ];
    let addressComponents = result.address_components;
    //Go backwards through the address components because google sorts them by seniority,
    //and we want to add the most important data and ignore excess information
    //in the same category
    for (let i = addressComponents.length - 1; i > -1; i--) {
        for (let j = addressMappers.length - 1; j > -1; j--) {
            //If our address already has a value for one of its fields, don't add
            //unnecessary excess data to it unless it's the house number or postcode
            if (parsedAddress[j].value !== ""
                && parsedAddress[j].type !== "number"
                && parsedAddress[j] !== "postcode") {
                continue;
            }
            for (let k = 0; k < addressMappers[j].items.length; k++) {
                //Prioritise address component types in the order set in the addressMappers
                //schema, throwing out excess fields that google gives us
                if (addressComponents[i].types[0] === addressMappers[j].items[k]
                    && (parsedAddress[j].value === ""
                        || parsedAddress[j].type === "number"
                        || parsedAddress[j] === "postcode")) {
                    parsedAddress[j].value += (parsedAddress[j].value === "" ? "" : " ")
                        + (parsedAddress[j].type === "state"
                            ? addressComponents[i].short_name
                            : addressComponents[i].long_name);
                }
            }
        }
    }

    let address = {
        street: "",
        city: "",
        state: "",
        postcode: ""
    };
    for (let a = 0; a < parsedAddress.length; a++) {
        if (parsedAddress[a].type === "country") {
            continue;
        }
        if (parsedAddress[a].type === "number") {
            address.street += parsedAddress[a].value;
        }
        else if (parsedAddress[a].type == "street" && parsedAddress[a].value !== "") {
            address.street += (address.street !== "" ? " " : "") + parsedAddress[a].value;
        }
        else if (parsedAddress[a].type == "suburb" && parsedAddress[a].value !== "") {
            address.street += (address.street !== "" ? ", " : "") + parsedAddress[a].value;
        }
        else {
            address[parsedAddress[a].type] = parsedAddress[a].value;
        }
    }
    address.coordinates = { latitude, longitude };

    let countryName = parsedAddress.filter(i => i.type === "country")[0].value;
    let params = {};
    params.filter = equal("Name", countryName, "String");
    let response = await axios.post('/api/Countries/List', params);
    let countries = response.data.items;
    if (countries.length !== 1) {
        this.$store.dispatch('alert/error', "The address's country is not recognised");
        return;
    }
    address.countryId = countries[0].id;
    address.country = countries[0].name;

    return(address);
}
